import { Extension } from '@tiptap/core';
import { Plugin } from 'prosemirror-state';
import { Fragment } from 'prosemirror-model';

export const CustomPasteExtension = Extension.create({
  name: 'customPaste',

  addProseMirrorPlugins() {
    return [
      new Plugin({
        props: {
          handlePaste: (view, event, slice) => {
            let transaction = view.state.tr;
            let insertPos = transaction.selection.from;

            slice.content.forEach(node => {
              if (node.type.name === 'paragraph') {
                let content = Fragment.empty;
                node.content.forEach(childNode => {
                  content = content.append(Fragment.from(childNode.mark([])));
                });
                if (content.size !== 0) {
                  const newParagraphNode = node.type.create(node.attrs, content, node.marks);
                  transaction = transaction.insert(insertPos, newParagraphNode);
                  insertPos += newParagraphNode.nodeSize;
                }
              } else {
                const textContent = node.textContent;
                transaction = transaction.insertText(textContent, insertPos);
                insertPos += textContent.length;
              }
            });

            if (transaction.docChanged) {
              view.dispatch(transaction);
              return true;
            }
            return false;
          },
        },
      }),
    ];
  },
});
