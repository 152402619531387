import React, { useRef, useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import { useFormContext } from "react-hook-form";
import useApi from "../../hooks/useApi";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 12px 12px 40px 12px;
  width: 100%;
`;

export const Preview = ({attributeTerms, allTags, allCategories, creativeOptions}) => {
  const ref = useRef();
  const { api } = useApi();
  const [creative, setCreative] = useState(undefined);
  const [iframeHeight, setIframeHeight] = useState("0px");
  const [iframeSrc, setIframeSrc] = useState('')
  const { watch } = useFormContext();
  const values = watch();

  const fetchCreative = useCallback(async () => {
    try {
      const creative = (await api.getLoggedinUserCreative())?.data;
      setCreative(creative);
    } catch (e) {
      console.log(e);
    }
  }, [api]);

  // update iframe height when content has changed
  useEffect(() => {
    const iframe = ref.current;
    fetchCreative()

    const updateHeight = () => {
      if (iframe?.contentWindow?.document) {
        setIframeHeight(iframe.contentWindow.document.documentElement.scrollHeight + 'px');
      }
    };

    const observer = new MutationObserver(updateHeight);

    if (iframe) {
      iframe.addEventListener('load', () => {
        updateHeight();
        const contentDocument = iframe.contentWindow.document;
        observer.observe(contentDocument.body, {
          childList: true,
          subtree: true,
          attributes: true,
        });
      });
    }

    return () => {
      observer.disconnect();
    };
  }, []);

  useEffect(() => {
    if (values && Object.keys(values).length > 0) {
      const findCreative = creative || creativeOptions.find(item => item.id === values.creative)
  
      let colors = '';
      let sizes = '';
    
      if (values.variations) {
        for (const item of values.variations) {
          const attributes = item.attributes;
          for (const attributeTermId of attributes) {
            let foundAttributeTerm = attributeTerms.find(({ attributeId }) => attributeId === attributeTermId);
            if (foundAttributeTerm) {
              if (foundAttributeTerm.attribute.name === 'Kleur') {
                if (colors) {
                  colors += ',';
                }
                colors += foundAttributeTerm.name;
              }
              if (foundAttributeTerm.attribute.name === 'Maten') {
                if (sizes) {
                  sizes += ',';
                }
                sizes += foundAttributeTerm.name.replace(',', '.')
              }
            }
          }
        }
      }
    
      let categoryName = typeof category === 'object'
        ? values.category?.name
        : allCategories.find(({id}) => id === values.category)?.name;
      const productName = encodeURIComponent(values.name || '')
      const regularPrice = encodeURIComponent(values.regularPrice || '')
      const salePrice = encodeURIComponent(values.salePrice || '')
      const shortDescription = encodeURIComponent(values.shortDescription || '')
      const height = encodeURIComponent(values.height || '')
      const width = encodeURIComponent(values.width || '')
      const length = encodeURIComponent(values.length || '')
      const categoryNameUri = encodeURIComponent(categoryName || '')
      const sku = encodeURIComponent(values.variations ? values.variations[0]?.sku : '')
      const wooUrl = encodeURIComponent(values.wooUrl || '')
      const colorsUri = encodeURIComponent(colors || '')
      const sizesUri = encodeURIComponent(sizes || '')
      const creativeName = encodeURIComponent(findCreative ? findCreative.name : '')
      const creativeDescription = encodeURIComponent(findCreative ? findCreative.description : '')
      let src = `/preview/product.html?productName=${productName}&regularPrice=${regularPrice}&salePrice=${salePrice}&shortDescription=${shortDescription}&height=${height}&width=${width}&length=${length}&categoryName=${categoryNameUri}&sku=${sku}&wooUrl=${wooUrl}&colors=${colorsUri}&sizes=${sizesUri}`;
      if (values.images && values.images.length > 0) {
        src = src + `&images=${encodeURIComponent(values.images.map(image => image.absoluteUrl).join(','))}`;
      }
      if (findCreative?.name) {
        src = src + `&creativeName=${creativeName}`;
      }
      if (findCreative?.description) {
        src = src + `&creativeDescription=${creativeDescription}`;
      }
      if (values.tags && values.tags.length > 0) {
        let selectedTagIds = values.tags.map(tag => tag.id);
        let intersect = selectedTagIds.filter(tagId => values.tagIds.includes(tagId));
        if (intersect.length !== values.tagIds.length || values.tagIds.length === 0) {
          const tagsRes = (values.tagIds.map(tagId => allTags?.find(tag => tag.id === tagId)));
          src = src + `&tags=${encodeURIComponent(tagsRes.map(tag => tag.name).join(','))}`
        }
      }
  
      setIframeSrc(src)
    }
  }, [allCategories, allTags, attributeTerms, creativeOptions, values, creative])

  if (!values || Object.keys(values).length === 0) {
    return null;
  }

  const WooLink = () => {
    if (values.wooUrl) {
      return (
        <span>
          View on{" "}
            <a rel="noreferrer" target="_blank" href={values.wooUrl}>
            swanmarket.nl
          </a>
        </span>
      );
    }

    return null;
  };

  const onIframeLoad = () => {
    setTimeout(() => {
      if (ref.current?.contentWindow?.document) {
        setIframeHeight(
          ref.current.contentWindow.document.body.scrollHeight + 'px'
        )
      }
    }, 1300)
  };

  return (
    <Wrapper>
      <h3>Preview</h3>
      <WooLink />
      <iframe
        ref={ref}
        onLoad={onIframeLoad}
        height={iframeHeight}
        title="Preview"
        src={iframeSrc}
        width="100%"
        style={{border: "none"}}
      />
    </Wrapper>
  );
};
