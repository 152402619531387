import React, { useCallback, useState, useEffect } from "react";
import { useRefresh, useNotify } from "react-admin";
import { Button } from "@mui/material";
import useApi from "../../hooks/useApi";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { randomSku } from "../../utils/common";
import { Dialog, DialogTitle, DialogContent, TextField as TextFieldMui } from '@mui/material';
import { getApiRoute } from "../../config/routes";
import errorMessageHandler from "../../Api/errorMessageHandler";

const ProductDuplicateButton = React.memo(({ text, recordId, ...props }) => {
  const { api } = useApi();
  const refresh = useRefresh();
  const notify = useNotify()
  const [name, setName] = useState(null)
  const [dialog, setDialog] = useState(false);
  const [record, setRecord] = useState()

  const openDialog = useCallback(async (e) => {
    e.stopPropagation()
    setDialog(true)
    if (recordId) {
      await api
      .get(getApiRoute(`products/${recordId}`))
      .then(({ data }) => {
        console.log(data)
        setRecord(data)
        setName(data.name)
      })
      .catch((e) => {
        notify(errorMessageHandler(e), 'error', {}, false, 100000)
      })
    }
  }, [api, notify, recordId])

  const  downloadImageAsBlob = useCallback(async (imageUrl) => {
    try {
      const response = await fetch(imageUrl);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      const imageBlob = await response.blob();
  
      return imageBlob;
    } catch (error) {
      console.error("Load image error", error);
    }
  }, [])

  const uploadImage = useCallback(async (image) => new Promise(async (resolve, reject) => {
        const formData = new FormData()
        const fileName = image.url.replace('/uploads/', '')
        const blob = await downloadImageAsBlob(image.absoluteUrl)
        formData.append('file', blob, fileName)
        formData.append('sort', image.sort)
        return api
        .post(getApiRoute('images'), formData)
        .then((resp) => {
            resolve(resp)
        })
        .catch((e) => {
          notify(errorMessageHandler(e), 'error', {}, false, 100000)
          reject()
        })
  }), [api, downloadImageAsBlob, notify])

  const duplicateProduct = useCallback(async () => {
   try {
    let creative
    await api.getLoggedinUserCreative().then((res) => {
      creative = res.data
    });
    
    const res = await Promise.all(record.images.map(img => uploadImage(img)))
    const imagesIds = res.map(item => item.data.id)
    const parsedData = {
        ...record,
        name,
        creative: record.creative ? record.creative.id : creative.id,
        category: record.category.id,
        vat: record.vat.id,
        variations: record.variations.map(variation => ({
          stockQty: variation.stockQty,
          sku: randomSku(3, 7),
          attributes: variation.attributes.map(attribute => attribute.id)
        })),
        images: imagesIds,
        tags: record.tags.map(tag => tag.id)
      }
      await api.duplicateProduct(parsedData).then(refresh)
      setDialog(false)
   } catch (error) {
    console.log(error, 'error')
   }
  }, [record, name, api, refresh, uploadImage])

  return (
    <>
        <Button onClick={openDialog}>
            <ContentCopyIcon /> {text || 'Duplicate'}
        </Button>
        <Dialog onClose={() => { setDialog(false) }} onClick={(e) => { e.stopPropagation() }} open={dialog}>
            <DialogTitle>
                Duplicate product
            </DialogTitle>
            <DialogContent>
                <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', padding: '10px 0'}}>
                <TextFieldMui label="New name" value={name} onChange={(e) => { setName(e.target.value) }} />
                <Button variant="contained" color="primary" disabled={!record || !name || name === record.name} fullWidth onClick={duplicateProduct}>Duplicate</Button>
                <Button variant="flat" color="error" fullWidth onClick={() => {setDialog(false)}}>Close</Button>
                </div>
            </DialogContent>
        </Dialog>
    </>

  );
});

export default ProductDuplicateButton;
