import React, { useEffect, useState, useCallback } from "react";
import { InputAdornment } from "@mui/material";
import styled from "styled-components";

import {
  SimpleForm,
  Edit,
  required,
  maxLength,
  PasswordInput,
  useNotify,
  useUpdate,
  BooleanInput,
  DateInput,
  ImageInput,
  ImageField,
  SaveButton,
  Toolbar,
  DeleteButton,
  CheckboxGroupInput,
  Confirm,
  FormDataConsumer,
  FunctionField,
} from "react-admin";
import { useForm} from "react-hook-form"
import { SectionCard, SectionInfo, SectionInfoImages, SectionInputs } from "../section";
import { isValidEmail } from "../../utils/uri";
import { validateIBAN, transformWhatsappLink } from '../../utils/common'
import { useRedirect } from "react-admin";
import { ImagesInput } from "../images-input";
import { ImagesProvider } from "../../contexts/images";
import { TextInput, NumberInput } from "../app/OutlinedInputs";
import errorMessageHandler from "../../Api/errorMessageHandler";
import { formatUTCDate } from "../../utils/common";
import { getApiRoute } from "../../config/routes";
import useApi from "../../hooks/useApi";
import { CustomRichTextInput } from '../rich-text-input'
import { VatInput } from "../vat-input";
import withTitle from '../with-title'
import useFiles from "../../hooks/useFiles";
import { EmailLoginButton } from "./actions/EmailLoginButton";
import { resizeImage } from "../../utils/images";
import { CountryInput } from "../country-input";
import { CardComponent } from "../edit-component";
import { EmailOnboardingButton } from "./actions/EmailOnboardingButton";

const StyledTextInput = styled(TextInput)`
  flex: 1;
`
const StyledNumberInput = styled(NumberInput)`
  flex: 1;
`

const CreativeEdit = ({ ...props }) => {
  const { api } = useApi();
  const notify = useNotify();
  const [update] = useUpdate();
  const redirect = useRedirect();
  const [isEmailError] = useState(false)
  const [isEmailValid] = useState(true)
  const [image, setImage] = useState(null);
  const [images, setImages] = useState([]);
  const [vacationDialog, setVacationDialog] = useState(false);
  const [vacationDays, setVacationDays] = useState(null)
  const [stores, setStores] = useState([])
  const [events, setEvents] = useState([])
  const [isKor, setIsKor] = useState(false)
  const [creative, setCreative] = useState(null)
  const { imageMimeTypes } = useFiles()
  const [isOutsideNl, setIsOutsideNl] = useState(true)
  const { reset } = useForm();

  const onChangeKor = useCallback((e) => {
    setIsKor(e.target.checked)
  }, [])

  const onChangeCountry = useCallback((val) => {
    setIsOutsideNl(!['nl'].includes(val.toLowerCase()))
  }, [])

  const fetchCreative = useCallback(async (id) => {
    await api.get(getApiRoute(`creatives/${id}`)).then((res) => {
      setCreative(res.data)
      setIsKor(res.data.kor)
      setIsOutsideNl(!['nl'].includes(res.data.country?.id?.toLowerCase()))
    })
  }, [api])

  useEffect(() => {
    api.get(getApiRoute('creative-stores')).then(({ data }) => {
      setStores(data.data)
    })
    api.get(getApiRoute('creative-events')).then(({ data }) => {
      setEvents(data.data)
    })
  }, [api])

  const validateVacationUntil = (value, allValues) => {
    if (allValues.onVacationFrom && !value) {
      return 'You must also select a "Until" date';
    }

    if (allValues.onVacationFrom && value) {
      if (new Date(allValues.onVacationFrom) > new Date(value)) {
        return '"Until" date should be after "From" date';
      }
    }

    return undefined;
  }

  const save = async (values) => {
    // check vacation > 30 days
    const dateFromObj = new Date(values.onVacationFrom);
    const dateUntilObj = new Date(values.onVacationUntil);
    
    const diff = dateUntilObj - dateFromObj;
    
    const diffDays = diff / (1000 * 60 * 60 * 24);
    
    if (diffDays > 30) {
      setVacationDays(diffDays)
      setVacationDialog(true)
    } else {
      await saveFunction(values)
    }
  };

  const saveFunction = async (values) => {
    try {
      const whatsappNumber = transformWhatsappLink(values.whatsappLink);
      const data = {
        ...values,
        onVacationFrom: formatUTCDate(values.onVacationFrom),
        onVacationUntil: formatUTCDate(values.onVacationUntil),
        image: values.image?.id || image?.id || null,
        images: values.images?.map((image) => typeof image === 'object'
          ? image.id
          : image
        ),
        whatsappLink: whatsappNumber?.length ? `wa.me/${transformWhatsappLink(whatsappNumber[0])}` : null
      };
      delete data.id;
      delete data.createdAt;
      const { email, plainPassword } = data.user;

      if (!email) {
        notify("Email is missing.", "danger");
      } else if (!isValidEmail(email)) {
        notify("Email is invalid.", "danger");
      }

      if (!email || !isValidEmail(email)) {
        return;
      }

      const emailNew = creative.user.email !== email ? email : undefined

      if (email !== "" && isValidEmail(email) && (emailNew || plainPassword)) {
        const res = await api.patch(getApiRoute(`users/${data.user.id}`), { email: emailNew, plainPassword }).catch(error => notify(errorMessageHandler(error), "danger"))
        if (!res) {
          return null
        }
      }

      await update(
        "creatives",
        {
          id: values.id,
          data,
        },
        {
          returnPromise: true,
          onSuccess: () => {
            notify("Changes saved", "success");
            redirect("/creatives");
          },
        }
      );
    } catch (error) {
      notify(errorMessageHandler(error), "error", {}, false, 100000);
      reset(values)
    }
  }

  const handleDialogClose = () => setVacationDialog(false)
  const handleConfirm = (values) => {
    saveFunction(values);
    setVacationDialog(false)
  }

  const onChangeImage = async (file) => {
    if (!file) {
      notify(`Bestandsformaat niet ondersteund. Bruikbare bestandsformaten zijn: JPG en PNG.`, 'error')
      setImage(null);
    }
    let reader = new FileReader();

    reader.addEventListener("load", () => {
      let image = new Image();
      image.src = reader.result;
      image.addEventListener("load", async () => {
        let formData = new FormData();

        const resizedFile = await resizeImage(file)
        formData.append("file", resizedFile);
        formData.append("sort", 1);
        api
          .post(getApiRoute("images"), formData)
          .then((image) => {
            setImage(image.data);
          })
          .catch((e) => {
            console.log(e);
            notify(errorMessageHandler(e), "error", {}, false, 100000);
          });
      });
    });

    if (file?.type && file?.type.indexOf('image') !== -1) {
      reader.readAsDataURL(file);
    }
  };

  const PostEditToolbar = (props) => {
    return (
      <Toolbar {...props}>
        <SaveButton />
        <DeleteButton />
      </Toolbar>
    );
  };

  const EventOption = (record) => {
    return (
      <div style={{ display: 'flex', flexDirection: 'column', border: '1px solid gray', borderRadius: 10, padding: '5px', margin: '5px' }}>
        <b>{record.name}</b>
        <span style={{ fontSize: '14px', color: 'gray' }}>{record.takePlaceOn.formatted6}, {record.time.from} - {record.time.to}</span>
        <span style={{ fontSize: '14px', color: 'gray' }}>{record.location}</span>
      </div>
    )
  }


  const passwordValidate = (value, allValues) => {
    if (value && value.length < 8) {
        return 'This value is too short. It should have 8 characters or more.'
    }
  }

  // const roles = JSON.parse(localStorage.getItem("roles")) || [];


  return (
    <div>
      <h1>Edit</h1>
      <ImagesProvider images={images} setImages={setImages}>
        <Edit component={CardComponent} {...props}>
          <SimpleForm
            sx={{ padding: 0, width: '100%' }}
            onSubmit={save}
            toolbar={<PostEditToolbar />}
          >
            <FormDataConsumer>
              {({ formData, ...rest }) => {
                return (
                  <>
                    <SectionCard>
                      <SectionInfo title={'User'}></SectionInfo>
                      <SectionInputs>
                        <TextInput
                          error={isEmailError || !isEmailValid}
                          label="Email"
                          source="user.email"
                          validate={required()}
                          helperText={
                            !isEmailValid
                              ? 'Email is not valid'
                              : isEmailError
                              ? 'Required'
                              : '\u00A0'
                          }
                        />
                        <PasswordInput
                          source="user.plainPassword"
                          label="Password"
                          variant="outlined"
                          inputProps={{ autoComplete: 'new-password' }}
                          validate={passwordValidate}
                        />
                        <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                          <EmailOnboardingButton variant="contained" onlyIcon />
                          <EmailLoginButton variant="contained" />
                        </div>
                      </SectionInputs>
                    </SectionCard>
                    <SectionCard>
                      <SectionInfo
                        title={'Creative profile'}
                      ></SectionInfo>
                      <SectionInputs>
                        <TextInput
                          autoFocus
                          label="Name"
                          source="name"
                          validate={required()}
                        />
                        <TextInput
                          label="Slug"
                          source="slug"
                          validate={required()}
                        />
                        <TextInput label="SKU prefix" source="skuPrefix" />
                        <NumberInput
                          label="Exact Code"
                          source="eoCode"
                          InputLabelProps={{ shrink: true }}
                        />
                        <CustomRichTextInput
                          label="Description"
                          source="description"
                          name="description"
                        />
                        <TextInput
                          multiline
                          label="Meta description"
                          source="metaDescription"
                          validate={[maxLength(160)]}
                          sx={{
                            [`& textarea`]: {
                              minHeight: '4em',
                            },
                          }}
                        />
                      </SectionInputs>
                    </SectionCard>
                    <SectionCard>
                      <SectionInfo title="Account images">
                        <SectionInfoImages />
                      </SectionInfo>
                      <SectionInputs>
                        <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
                          <h4 style={{ marginBottom: 0 }}>Profile image</h4>
                          <ImageInput
                            onChange={onChangeImage}
                            source="image"
                            label=" "
                            sx={{ border: '3px dashed rgba(0, 0, 0, 0.12)' }}
                            accept={imageMimeTypes}
                          >
                            <ImageField source="absoluteUrl" title="title" />
                          </ImageInput>
                          {image && (
                            <div>
                              <StyledImage src={image.absoluteUrl} alt={image.id} />
                            </div>
                          )}
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
                          <h4 style={{ marginBottom: 0 }}>Style & impression gallery</h4>
                          <ImagesInput source="images" id="images" />
                        </div>
                      </SectionInputs>
                    </SectionCard>
                    <SectionCard>
                      <SectionInfo title={'Public information'}></SectionInfo>
                      <SectionInputs>
                        <BooleanInput
                          label="Show on public profile page"
                          name={'showSettings'}
                          source="showSettings"
                        />
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <StyledTextInput label="Website" source="url" />
                          <span style={{ marginLeft: '5px' }}></span>
                          <BooleanInput
                            label="Show on site"
                            source="showUrl"
                            name="showUrl"
                          />
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <StyledTextInput
                            label="Public email address"
                            source="publicEmail"
                          />
                          <span style={{ marginLeft: '5px' }}></span>
                          <BooleanInput
                            label="Show on site"
                            source="showPublicEmail"
                            name="showPublicEmail"
                          />
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <StyledTextInput
                            label="Phone number"
                            source="phoneNumber"
                          />
                          <span style={{ marginLeft: '5px' }}></span>
                          <BooleanInput
                            label="Show on site"
                            source="showPhoneNumber"
                            name="showPhoneNumber"
                          />
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            marginBottom: '15px',
                          }}
                        >
                          <StyledNumberInput
                            label="WhatsApp"
                            source="whatsappLink"
                            placeholder="31612345678"
                            helperText="Fill phone with country code"
                            format={transformWhatsappLink}
                            InputLabelProps={{ shrink: true }}
                          />
                          <span style={{ marginLeft: '5px' }}></span>
                          <BooleanInput
                            label="Show on site"
                            source="showWhatsappLink"
                            name="showWhatsappLink"
                          />
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <StyledTextInput
                            label="Instagram url"
                            source="instagramLink"
                          />
                          <span style={{ marginLeft: '5px' }}></span>
                          <BooleanInput
                            label="Show on site"
                            source="showInstagramLink"
                            name="showInstagramLink"
                          />
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <StyledTextInput
                            label="Facebook url"
                            source="facebookLink"
                          />
                          <span style={{ marginLeft: '5px' }}></span>
                          <BooleanInput
                            label="Show on site"
                            source="showFacebookLink"
                            name="showFacebookLink"
                          />
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <StyledTextInput
                            label="Pinterest"
                            source="pinterestLink"
                          />
                          <span style={{ marginLeft: '5px' }}></span>
                          <BooleanInput
                            label="Show on site"
                            source="showPinterestLink"
                            name="showPinterestLink"
                          />
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <StyledTextInput
                            label="Twitter url"
                            source="twitterLink"
                          />
                          <span style={{ marginLeft: '5px' }}></span>
                          <BooleanInput
                            label="Show on site"
                            source="showTwitterLink"
                            name="showTwitterLink"
                          />
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <StyledTextInput label="Tiktok" source="tiktokLink" />
                          <span style={{ marginLeft: '5px' }}></span>
                          <BooleanInput
                            label="Show on site"
                            source="showTiktokLink"
                            name="showTiktokLink"
                          />
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <StyledTextInput label="Snapchat" source="snapchatLink" />
                          <span style={{ marginLeft: '5px' }}></span>
                          <BooleanInput
                            label="Show on site"
                            source="showSnapchatLink"
                            name="showSnapchatLink"
                          />
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <StyledTextInput label="Mastodon" source="mastodonLink" />
                          <span style={{ marginLeft: '5px' }}></span>
                          <BooleanInput
                            label="Show on site"
                            source="showMastodonLink"
                            name="showMastodonLink"
                          />
                        </div>
                      </SectionInputs>
                    </SectionCard>
                    <SectionCard>
                      <SectionInfo title={'Business information'}></SectionInfo>
                      <SectionInputs>
                        <TextInput
                          label="Address"
                          source="streetAddress"
                        />
                        <TextInput label="Postal code" source="postalCode" />
                        <TextInput label="City" source="city" />
                        <CountryInput label="Country" source="country" onChange={onChangeCountry} />
                        <TextInput
                          label="Bank account IBAN"
                          source="iban"
                          validate={validateIBAN}
                        />
                        <TextInput
                          label="Bank account holder (tenaamstelling)"
                          source="bankAccountHolder"
                        />

                        {formData.iban &&
                        formData.iban.length &&
                        !formData.iban.includes('NL') && (
                          <TextInput
                            label="Bank SWIFT number"
                            source="bic"
                            validate={required()}
                          />
                        )}

                        <TextInput
                          label="COC Number (KVK nummer)"
                          source="kvkNumber"
                        />
                        <TextInput label="VAT Number (BTW nummer)" source="btwNumber" />
                        <FunctionField
                          render={(record) => {
                            if (!creative) {
                              fetchCreative(record?.id)
                              return null
                            }

                            return (
                              <VatInput
                                isEdit
                                isOutsideNl={isOutsideNl}
                                isKor={isKor}
                                label="VAT setting"
                                source="vat"
                                validate={required()}
                              />
                            )
                          }}
                        />

                        <BooleanInput
                          source="kor"
                          label="KOR (Small Business Agreement)"
                          onChange={onChangeKor}
                        />
                        <TextInput label="WebwinkelKeur ID" source="webwinkelId" />
                      </SectionInputs>
                    </SectionCard>

                    <SectionCard>
                      <SectionInfo title={'Account settings'}></SectionInfo>
                      <SectionInputs>
                        <BooleanInput source="active" />
                        <BooleanInput name={'member'} source="member" />
                        <NumberInput
                          label="Commission percentage"
                          source="commissionPercentage"
                          options={{
                            InputProps: {
                              endAdornment: (
                                <InputAdornment position="end">
                                  %
                                </InputAdornment>
                              ),
                            },
                          }}
                        />

                        <BooleanInput
                          source="publishProductsDirectly"
                          label="Publish products automatically"
                        />

                        <BooleanInput
                          label="Receive new order emails"
                          source="sendNewOrderEmail"
                          name="sendNewOrderEmail"
                          defaultValue={true}
                          defaultChecked={true}
                        />

                        <TextInput label="Hubspot ID" source="hsId" />
                        <Confirm
                          isOpen={vacationDialog}
                          title={`Are you sure you are on holiday for ${vacationDays} days?`}
                          onConfirm={() => {
                            handleConfirm(formData)
                          }}
                          onClose={handleDialogClose}
                        />
                        <DateInput variant="outlined" source="onVacationFrom" />
                        <DateInput
                          variant="outlined"
                          source="onVacationUntil"
                          validate={[validateVacationUntil]}
                        />
                      </SectionInputs>
                    </SectionCard>
                    {Boolean(stores.length) && (
                      <SectionCard>
                        <SectionInfo title={'Stores'}>
                          My products are available in the following Swan
                          stores:
                        </SectionInfo>
                        <SectionInputs>
                          <CheckboxGroupInput
                            source="stores"
                            optionValue="id"
                            optionText="name"
                            choices={stores}
                            row={false}
                          />
                        </SectionInputs>
                      </SectionCard>
                    )}
                    {Boolean(events.length) && (
                      <SectionCard>
                        <SectionInfo title={'Events'}>
                          Show our site visitors on which Swan markets you have
                          a stand. For them a good chance for a meet and greet,
                          and you will sell more. For booking a stand, please
                          visit
                          <br />
                          <a
                            href="https://www.theofflinecompany.nl/meedoen/"
                            target="_blank"
                            rel="noreferrer"
                          >
                            https://www.theofflinecompany.nl/meedoen/
                          </a>
                        </SectionInfo>
                        <SectionInputs>
                          <CheckboxGroupInput
                            source="events"
                            optionValue="id"
                            optionText={EventOption}
                            choices={events}
                            row={false}
                          />
                        </SectionInputs>
                      </SectionCard>
                    )}
                  </>
                )}
          }
          </FormDataConsumer>
          </SimpleForm>
        </Edit>
      </ImagesProvider>
    </div>
  );
};

const StyledImage = styled.img`
  width: 100%;
  max-height: 200px;
  object-fit: contain;
`;

export default withTitle(CreativeEdit, 'Creative Edit');
