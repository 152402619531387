import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useInput, useNotify, useRecordContext } from "react-admin";
import { ImageItem } from "./image-item";
import { getApiRoute } from "../../config/routes";
import errorMessageHandler from "../../Api/errorMessageHandler";
import useApi from "../../hooks/useApi";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useDropzone } from "react-dropzone";
import { useImages } from "../../contexts/images";
import { CircularProgress } from "@mui/material";
import { MAX_IMAGE_SIZE_MB, resizeImage } from "../../utils/images";
import { generateId } from "../../utils/uri";
import { reject } from 'lodash'
import useFiles from "../../hooks/useFiles";

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)
  return result
}

const Images = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  overflow-x: auto;
  height: 100%;
`

const DropzoneAreaWrapper = styled.div`
  height: 244px;
  position: relative;
  border: 3px dashed rgba(0, 0, 0, 0.12);
  display: flex;
  align-items: center;
  justify-content: center;
`

const DropzoneAreaContentWrapper = styled.div`
  text-align: center;
`

export const ImagesInput = ({ source, id, ...rest }) => {
  const { images, setImages } = useImages()
  const record = useRecordContext()
  const { api } = useApi()
  const {
    field: { onChange },
  } = useInput({ record, source, ...rest })
  const notify = useNotify()
  const [loading, setLoading] = useState(false)
  const { imageMimeTypes } = useFiles()

  const onDropImage = async (files) => {
    if (files.length > 0) {
      setLoading(true)
      let _images = [...images]

      const uploadFile = (file) =>
        new Promise(async (resolve) => {
          const resizedFile = await resizeImage(file)
          if (resizedFile.size > MAX_IMAGE_SIZE_MB * 1000000) {
            setLoading(false)
            notify(`File size exceeds ${MAX_IMAGE_SIZE_MB}MB`, 'error')

            return reject(`File size exceeds ${MAX_IMAGE_SIZE_MB}MB`, 'error')
          }

          const formData = new FormData()
          const fileName = `${generateId()}.jpg`

          formData.append('file', resizedFile, fileName)
          formData.append('sort', _images.length)

          await api
            .post(getApiRoute('images'), formData)
            .then((resp) => {
              _images = [..._images, resp.data]
              resolve(_images)
            })
            .catch((e) => {
              notify(errorMessageHandler(e), 'error', {}, false, 100000)
              reject(e)
            })
        })

      try {
        // Upload images here
        await Promise.all(files.map(uploadFile))
        setLoading(false)
        setImages(_images)
      } catch (error) {
        setLoading(false)
      }
    } else {
      notify(`Bestandsformaat niet ondersteund. Bruikbare bestandsformaten zijn: JPG en PNG.`, 'error')
    }
  }

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: onDropImage,
    accept: {
      'image/png': imageMimeTypes ? imageMimeTypes : [],
      'image/jpg': imageMimeTypes ? imageMimeTypes : [],
      'image/jpeg': imageMimeTypes ? imageMimeTypes : []
    },
  })

  useEffect(() => {
    if (record?.images?.sort) {
      setImages(record.images.sort((a, b) => a.sort - b.sort))
    }
  }, [record, setImages])

  useEffect(() => {
    onChange(images.map((img) => img))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [images])

  const onChangeOrder = async (result) => {
    if (!result.destination) {
      return
    }

    setLoading(true)

    const reorderedImages = reorder(
      images,
      result.source.index,
      result.destination.index
    )

    setImages(reorderedImages)

    try {
      await Promise.all(
        reorderedImages.map((img, index) =>
          api.patch(getApiRoute(`images/${img.id}`), { sort: index })
        )
      )
    } catch (e) {
      notify(errorMessageHandler(e), 'error')
    }
    setLoading(false)
  }

  return (
    <div>
      <DropzoneAreaWrapper {...getRootProps()}>
        <input {...getInputProps()} id={id} multiple />
        {loading ? (
          <CircularProgress />
        ) : (
          <DropzoneAreaContentWrapper>
            <p>Drag and drop an image here, or click to select files</p>
            <svg
              focusable="false"
              viewBox="0 0 24 24"
              aria-hidden="true"
              height="51"
            >
              <path d="M19.35 10.04C18.67 6.59 15.64 4 12 4 9.11 4 6.6 5.64 5.35 8.04 2.34 8.36 0 10.91 0 14c0 3.31 2.69 6 6 6h13c2.76 0 5-2.24 5-5 0-2.64-2.05-4.78-4.65-4.96zM14 13v4h-4v-4H7l5-5 5 5h-3z"></path>
            </svg>
          </DropzoneAreaContentWrapper>
        )}
      </DropzoneAreaWrapper>

      <DragDropContext onDragEnd={onChangeOrder}>
        <Droppable droppableId="images" direction="horizontal">
          {(provided) => (
            <Images
              ref={provided.innerRef}
              className="images-holder"
              {...provided.droppableProps}
            >
              {images.map((img, index) => (
                <Draggable
                  key={String(img.id)}
                  draggableId={String(img.id)}
                  index={index}
                >
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <ImageItem
                        image={img}
                        saveImage={(newImage) => {
                          if (newImage) {
                            setImages((cur) =>
                              cur.map((item) =>
                                item.id === newImage.id ? newImage : item
                              )
                            )
                          } else {
                            setImages((cur) =>
                              cur.filter((item) => item.id !== img.id)
                            )
                          }
                        }}
                      />
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </Images>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  )
}